<template>
    <div id="start">

        <div class="parallax--wrapper">
            <div class="parallax landing__section"
            >
            <img 
                v-for="(slice, index) in slices"
                :key="index"
                :src="paraImgSrc(slice.src)" 
                :alt="'Background slice, ' + slice.src" 
                class="parallax__img"
                :class="slice.src"
                :style="'--sltz: -' + parseInt(index + 1) + '00px; --slsc: ' + parseInt(index + 2) + '; --sli:' + parseInt(index)"
                >
                <div class="parallax__titlecard">
                    <img :src="paraImgSrc('logo')" class="parallax__titlecard--item logo" alt="Logo Image">
                    <h1 class="parallax__titlecard--item title">Welcome</h1>
                    <h3 class="subtitle">lakatos.info</h3>
                </div>
                
            </div>

            <div class="parallax__scrollbuffer">
                <img :src="paraImgSrc('tree-cover')" class="parallax__scrollbuffer--cover" alt="Logo Image">
            </div>
            <div ref="scrollEnd" class="parallax__scrollend" />

        </div>

        <div class="enter--wrapper">
            <button class="enter">
                <router-link :to="{ name: 'home' }">
                    <span class="material-symbols-outlined">
                    keyboard_double_arrow_down
                    </span>
                </router-link>
            </button>
        </div>

    </div>
</template>


<script setup>
import { ref, watch } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import { useRouter } from 'vue-router';

const router = useRouter();
const scrollEnd = ref(null)
const scrollEndVisibility = useElementVisibility(scrollEnd)

watch(scrollEndVisibility, (newVal) => {
  if (newVal) {
    console.log('scroll')
    router.push({ name: 'home' })
  }
})
</script>


<script>  
export default {
    name: 'landing',
    components: {
        
    },
    data () {
        return {
            slices: [
                { src: 'trees' },
                { src: 'big-bridge' },
                { src: 'small-bridge' },
                { src: 'hills' },
                { src: 'planets' },
                { src: 'stars' }
            ]
        }
    },
    methods: {
        paraImgSrc(src) {
            return require('@/assets/parallax/' + src + '.svg')
        }
    }
}
</script>

<style lang="scss">
@keyframes hobble {
    0% { transform: translateY(0); }
    50% { transform: translateY(-1rem); }
    100% { transform: translateY(0); }
}
@keyframes fade {
    0% { 
        opacity: 0; 
        transform: translateY(-100%) translateZ(var(--sltz)) scale(var(--slsc));  
    }
    100% { 
        opacity: 1; 
        transform: translateY(0) translateZ(var(--sltz)) scale(var(--slsc)); 
    }
}

#start {
    --_start-enter: 10rem;

    position: absolute;
    z-index: 999;
    inset: 0;
    background: linear-gradient(to bottom,
                #1a1a1a,
                #1a2a3a
                );
    background: linear-gradient(to bottom, #141b1b, #183a4e, #667e83, black);
    max-width: unset;
    padding: 0;
    
    .enter {
        &--wrapper {
            display: grid;
            place-items: center;
            height: var(--_start-enter);
            width: 100%;

            position: absolute;
            top: calc(100dvh - var(--_start-enter)); left: 0;
            // background: linear-gradient(to top, var(--void-grey), transparent);
        }

        display: grid; 
        place-items: center;
        background: transparent;
        border: none;

        span { font-size: 8rem; }
        a {
            color: #ff9900;
            text-decoration: none;
            animation: hobble 3s infinite;
            &:hover { animation-play-state: paused; }
        }
    }
}


.parallax {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform-style: preserve-3d;
    z-index: -1;

    --sltz: 1px;
    --slsc: 1;
    --sli: 1;

    &--wrapper {
        height: 100vh;
        height: 100dvh;
        overflow-y: auto;
        overflow-x: hidden;
        perspective: 100px;
    }


    &__img {
        position: absolute; inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;

        animation: fade forwards 450ms;
        transform: translateZ(var(--sltz)) scale(var(--slsc));

    }
    &__titlecard {
        display: grid;
        place-items: center;
        padding-block-end: 5rem;

        &--item {
            grid-column: 1;
            grid-row: 1;
        }
        .title {
            font-size: 8vw;
            font-family: var(--display_font);
            text-transform: uppercase;
            text-shadow: 0 0 10px black;
            margin-block: auto;
        }
        .subtitle {
            font-size: 5vw;
            font-size: clamp(1vw, 2rem, 10vw);
            font-family: var(--display_font);
            font-weight: 300;
            font-style: oblique;
            text-shadow: 0 0 5px black;
            margin-block: 1rem;
        }
        .logo {
            width: 65%;
            max-width: 800px;
        }
    }
    
    &__scrollbuffer {
        background: black;
        height: 70dvh;
        overflow-y: visible;
        position: relative;

        &--cover {
            width: 100dvw;
            height: auto;
            object-fit: cover;
            position: absolute;
            bottom: 65dvh;
            background: linear-gradient(to top, var(--void-grey), transparent 75%);
            background: linear-gradient(to top, #183a4e, transparent 75%);
        }
    }
    &__scrollend {
        background: var(--void-grey);
        background: linear-gradient(to top, var(--void-grey), black);
        height: 300dvh;
    }
}
</style>