<template>
<div class="screen">
    <div class="spinner s1" />
    <div class="spinner s2" />
    <div class="spinner s3" />
    <div class="spinner s4" />
    <div class="spinner s5" />
    <div class="spinner s6" />
    <div class="spinner s7" />
    <div class="text">loading...</div>
</div>
</template>

<style lang="scss">
@keyframes spinner {
    from { transform: rotate(0deg) perspective(500px) translateZ(0); }
    to { transform: rotate(360deg) perspective(500px) translateZ(5rem); } 
}
.screen {
    height: 100dvh;
    width: 100dvw;
    position: absolute;
    inset: 0;
    z-index: 9999;
    background: #1a1a1a;
    overflow: hidden;

    --_spinnersize: 20vh;
    --_spinnertime: 1s;
    --_spinnercolor-main: #1b3030;
    --_spinnercolor-accent: #0a5353;

    .text {
        position: absolute;
        top: calc(50% - 1.5rem);
        left: 0;
        width: 100%;
        height: 3rem;

        display: grid;
        place-items: center;

        font-family: var(--display_font);
        font-size: 3rem;
        font-weight: 900;
        color: #eee;
    }
    .spinner {
        position: absolute;
        inset: calc(50% - (var(--_spinnersize) / 2));

        height: var(--_spinnersize);
        border-radius: 100%;
        aspect-ratio: 1;
        border: calc(var(--_spinnersize) / 10) solid var(--_spinnercolor-main);
        border-width: .1rem;
        border-right-color: var(--_spinnercolor-accent);
        animation: spinner var(--_spinnertime) ease-in-out alternate infinite;

        &.s2 { --_spinnersize: 30vh; --_spinnertime: 1.5s; }
        &.s3 { --_spinnersize: 40vh; --_spinnertime: 3s; }
        &.s4 { --_spinnersize: 65vh; --_spinnertime: 2.6s; }
        &.s5 { --_spinnersize: 85vh; --_spinnertime: 8s; }
        &.s6 { --_spinnersize: 110vh; --_spinnertime: 4.2s; }
        &.s7 { --_spinnersize: 150vh; --_spinnertime: 5s; }
    }
}

</style>