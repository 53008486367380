<template>

  <TheNavigation />
  <TheSplashScreen v-show="loadingStatus" />

  <main>
    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition">

          <template v-if="route.name !== 'landing'">
            <keep-alive>
              <component :is="Component" :key="route.path" class="content" />
            </keep-alive>
          </template>

          <template v-else>
            <component :is="Component" :key="route.path" class="content" />
          </template>

        </transition>
    </router-view>
  </main>

</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue';
import TheSplashScreen from '@/components/TheSplashScreen.vue';

export default {
  components: {
    TheNavigation, TheSplashScreen
  },
  data() {
    return {
      loadingStatus: true, // Initialize splash screen
    };
  },
  mounted() {
    // Simulated loading time
    setTimeout(() => {
      this.loadingStatus = false; // Hide splash screen
    }, 2000);
  },
}
</script>

<style lang="scss">
@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}


:root {

  --_nav-size: 5rem;

  --slz: 0;
  --slx: 0%;

  --cosmic-latte: #Fff8e7;
  --void-grey: #1a1a1a;
  --night-sky: #1a2a3a;
  --teal: #008899;
  --form_error: #ff5600;

  --display_font: 'Montserrat', sans-serif;
  --header_font: "Cairo", sans-serif;
  --base_font: 'Inter', sans-serif;
  --font_color: var(--cosmic-latte);

  --scroll-thumb: var(--cosmic-latte);
  --scroll-track: var(--void-grey);
}
*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    scroll-behavior: smooth;

    /* FIREFOX scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-thumb) var(--scroll-track); /*thumb | track*/
}
/* other scrollbars */
*::-webkit-scrollbar{
    height: 1rem; width: 1rem;
}
*::-webkit-scrollbar-track {
    width: 0; height: 0;
    background-color: var(--scroll-track);
    border-radius: .5rem;
}
*::-webkit-scrollbar-thumb {
    background: var(--scroll-thumb);
    border-radius: .5rem;
}

/* text highlight color when selected with cursor */
::selection {
  background: #fff8e7;
  color: #1a2a3a;
}


// SLIDE
.slide-left-enter-active, 
.slide-right-enter-active, 
.slide-left-leave-active, 
.slide-right-leave-active {
  position: absolute; inset: 0;
  transition: transform 350ms ease-in-out;
  z-index: 100;
}
.slide-right-leave-to,
.slide-left-enter,
.slide-left-leave,
.slide-left-enter-from { 
  transform: translateX(100%); z-index: 10;
  @include landscape { transform: translateY(100%); }
}
.slide-left-leave-to,
.slide-right-enter,
.slide-right-leave,
.slide-right-enter-from { 
  transform: translateX(-100%); z-index: 10;
  @include landscape { transform: translateY(-100%); }
}
.slide-right-enter-to,
.slide-left-enter-to { 
  transform: translateX(0); z-index: 10;
  @include landscape { transform: translateY(0); }
}


// Parallaxis
.parallaxis-enter-active,
.parallaxis-leave-active {
  position: absolute; inset: 0;
  transition: transform 450ms ease-in-out 300ms, opacity 300ms ease-out 350ms;
  z-index: 100;
}
.parallaxis-enter,
.parallaxis-enter-from,
.parallaxis-leave { 
  transform: translateY(100%); z-index: 10;
  opacity: 0;
  .parallax--slice { 
    animation: parallax-zoom 1.2s ease-in infinite reverse;
  }
}
.parallaxis-leave-to { 
  transform: translateY(-100%); z-index: 10; 
  opacity: 0;
  .parallax--slice { 
    animation: parallax-zoom 1.2s ease-in infinite reverse;
  }

}
.parallaxis-enter-to { 
  transform: translateY(0); z-index: 10; 
  opacity: 1;
}

#app {

  font-family: var(--base_font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font_color);
  font-weight: 200;

}

a {
    color: #3daa79;
    font-weight: 400;
    transition: color 250ms ease-in-out;
    &:hover, &:focus-visible {
      color: #67f0b3;
    }
}
hr { border-color: var(--teal); }
button {
  font-family: var(--base_font);
  padding: 0.5rem 1.5rem;
  cursor: pointer;

  background-image: linear-gradient(to bottom, var(--night-sky), var(--void-grey));
  border-color: var(--teal);
  border-radius: 0.25rem;

  color: var(--font_color);
  transition: background 350ms ease-in-out;

  &:hover, &:focus-visible {
    background-image: linear-gradient(to top, var(--night-sky), var(--void-grey));
    outline: invert;
  }
  &:active { background-image: linear-gradient(to bottom, var(--teal) -50%, var(--night-sky), var(--void-grey)); }
}

p {
  line-height: 1.45;
  margin-block-end: 1em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--header_font);
  margin-block: 2.5em .5em;
  letter-spacing: .1em;
  line-height: 120%;
  font-weight: 700;
}
h1 {
  letter-spacing: .15em;
  font-weight: 800;
}


body { 
  background-color: var(--void-grey);
}
main {
  position: absolute;
  inset: 0;
  overflow-y: auto;

  background-color: var(--void-grey);
  background-image: radial-gradient(var(--night-sky) 2px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;

  min-height: 100dvh;

  .content {
    padding: 1rem;
    padding-block-end: calc(var(--_nav-size) + 1rem);
    @include landscape {
      padding-inline-start: calc(var(--_nav-size) + 2rem);
      max-width: 950px;
    }
  }
}

</style>
