<template>
<div class="nav--wrap">
    <nav class="nav">
        
        <router-link class="nav__item" :to="{ name: 'home' }">
            <span class="material-symbols-outlined">sensor_occupied</span>
            <span class="nav__item--text">Home</span>
        </router-link>

        <router-link class="nav__item" :to="{ name: 'web' }">
            <span class="material-symbols-outlined">web</span>
            <span class="nav__item--text">Projects</span>
        </router-link>
            
        <router-link class="nav__item" :to="{ name: 'art' }">
            <span class="material-symbols-outlined">palette</span>
            <span class="nav__item--text">Gallery</span>
        </router-link>

        <router-link class="nav__item" :to="{ name: 'socials' }">
            <span class="material-symbols-outlined">alternate_email</span>
            <span class="nav__item--text">Contact</span>
        </router-link>
        
    </nav>

    <div class="banner">
        <router-link :to="{ name: 'landing' }" class="banner__img--link">
            <img class="banner__img" 
                id="logo" 
                alt="Theodor Lakatos personal logo" 
                :src="require('@/assets/logo.svg')"
                >
        </router-link>
        <div class="banner__text">lakatos.info</div>
    </div>
</div>

</template>

<style lang="scss">
@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

.nav {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    position: fixed;
    bottom: 0;
    z-index: 97;
    width: 100vw;
    height: var(--_nav-size);
    padding-block: 1rem;

    background: #fafafa33;
    backdrop-filter: blur(7px);

    &__item {
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: .5rem;

        &--text {
            text-transform: uppercase;
            letter-spacing: .25em;
            font-size: .5rem;
            font-weight: 300;
            text-wrap: nowrap;
        }
    }
    a { 
        color: var(--cosmic-latte);
        text-decoration: none;
        text-underline-offset: .5em;
        &.router-link-exact-active .nav__item--text {
            text-decoration: underline;
            text-decoration-thickness: .25em;
            text-decoration-color: #67f0b3;
        }
    }


    @include landscape {

    flex-flow: column nowrap;
    justify-content: flex-end;
    gap: 2rem;

    left: 0;
    width: var(--_nav-size);
    height: 100vh;

    overflow-x: clip;
    background: linear-gradient(135deg, #fafafa33, #fafafa00);
 
    }

}



.banner {

    display: flex;
    gap: 1rem;
    position: fixed;
    top: 0; left: 0;
    // bottom: calc(100vh - (var(--_nav-size) / 2) - 2rem);

    z-index: 98;

    width: 100%;
    padding: 1rem;

    background: #fafafa33;
    backdrop-filter: blur(60px);
    // background: linear-gradient(to bottom, var(--night-sky),var(--void-grey));

    #logo {
        height: calc(var(--_nav-size) / 2);
        width: auto;
    }
    &__img--link {
        display: grid;
        place-items: center;
    }
    &__text {
        display: flex;
        align-items: center;
        margin-inline: 1.5rem;
        margin-inline-start: auto;
        font-family: var(--display_font);
        font-size: calc(var(--_nav-size) / 4);
        font-weight: 100;
        letter-spacing: .25em;
    }

    @include landscape {

        top: 0;
        backdrop-filter: none;
        background: linear-gradient(to left,  var(--void-grey) 20%, transparent 50%);
        
        @media only screen and (height < 400px) {
            background: transparent;
            width: var(--_nav-size); overflow-x: hidden;
            &__text {
                display: none;
                visibility: hidden;
            }
        }

        #logo {
            height: auto;
            width: calc(var(--_nav-size) - 2rem);
        }
        &__img {
            position: relative; inset: 0;
            margin-block-end: auto;
            padding: 0;
            border: none;
            background: transparent;
            width: unset;
        }
    }
}
</style>