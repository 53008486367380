import { createRouter, createWebHistory } from 'vue-router'
import LandingView from '@/views/LandingView.vue'
import { inject } from 'vue';

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView,
    meta: {
      title: false,
      transition: 'parallaxis'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue'),
    meta: {
      title: 'Home, About Me'
    }
  },
  {
    path: '/web',
    name: 'web',
    component: () => import(/* webpackChunkName: "web" */ '@/views/ProjectsView.vue'),
    meta: {
      title: 'Web Projects'
    }
  },
  {
    path: '/art',
    name: 'art',
    component: () => import(/* webpackChunkName: "art" */ '@/views/ArtView.vue'),
    meta: {
      title: 'Art and Design'
    }
  },
  {
    path: '/socials',
    name: 'socials',
    component: () => import(/* webpackChunkName: "socials" */ '@/views/SocialView.vue'),
    meta: {
      title: 'Social Media, Contact'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // tabname
  document.title = to.meta.title ? 'lakatos.info | ' + to.meta.title : 'lakatos.info';
  
  // transitions
  const routeOrder = ['home', 'web', 'art', 'socials']; // route order
  const toRoute = to.name
  const fromRoute = from.name

  if (to.name === 'landing' || from.name === 'landing') { 
    toRoute === 'landing' ? to.meta.transition = 'none' : fromRoute === 'landing' ? to.meta.transition = 'parallaxis' : to.meta.transition = 'none';
    // no transition when landing page loads. coming from landing page, parallaxis transition down
    // slide is a fallback, see else
  } else if (fromRoute !== toRoute) {
    // slide direction based on order of the routes
    const isSlideLeft = routeOrder.indexOf(toRoute) > routeOrder.indexOf(fromRoute);
    to.meta.transition = isSlideLeft ? 'slide-left' : 'slide-right';
  } else {
    to.meta.transition = 'none'
  }

  //splashscreen injection, see App.vue, see TheSplashScreen.vue (component)
  // const setLoadingStatus = inject('setLoadingStatus');
  // setLoadingStatus(true);
  // setTimeout(() => {
  //   setLoadingStatus(false);
  // }, 800);

  next();
});

export default router
